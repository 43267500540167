
import { Vue, Component } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { douyinConfigData, douyinConfigEdit } from '@/api/douyin'

@Component({
  components: {
    MaterialSelect
  }
})
export default class config extends Vue {
  /** S Data **/
      // 表单数据
  form: any = {
    client_key: '', // client_key
    client_secret: '', // client_secret
    saas_domain: ''//域名
  }

  // 表单验证
  // eslint-disable-next-line @typescript-eslint/ban-types
  rules: object = {
    client_key: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
    client_secret: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
    saas_domain: [{ required: true, message: '必填项不能为空', trigger: 'blur' }]
  }

  /** E Data **/

  /** S Methods **/
  // 初始化表单数据
  initFormData () {
    douyinConfigData().then(res => {
      // 表单同步于接口响应数据
      for (const key in res) {
        // eslint-disable-next-line no-prototype-builtins
        if (!this.form.hasOwnProperty(key)) {
          continue
        }
        this.form[key] = res[key]
      }
    })
        .catch(() => {
          //this.$message.error('数据加载失败，请刷新重载')
        })
  }

  // 重置表单数据
  // onResetFrom() {
  //   this.initFormData()
  //   this.$message.info('已重置数据')
  // }

  // 提交表单
  onSubmitFrom (formName: string) {
    const refs = this.$refs[formName] as HTMLFormElement
    console.log(refs.validate((valid: boolean): boolean => valid))

    refs.validate((valid: boolean) => {
      if (!valid) {
        return this.$message.error('请完善信息')
      }
      const loading = this.$loading({ text: '保存中' })
      const params: any = { ...this.form }

      delete params.url
      delete params.business_domain
      delete params.js_secure_domain
      delete params.web_auth_domain

      douyinConfigEdit({
        ...params
      })
          .then(() => {
            this.$message.success('保存成功')
          })
          .catch(() => {
            this.$message.error('保存失败')
          })
          .finally(() => {
            loading.close()
          })
    })
  }

  /** E Methods **/

  /** S Life Cycle **/
  created () {
    this.initFormData()
  }

  /** E Life Cycle **/
}
